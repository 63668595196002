import $ from 'jquery';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
import mobileMenuToggle from './global/mobile-menu-toggle';
import menu from './global/menu';
import foundation from './global/foundation';
import quickView from './global/quick-view';
import cartPreview from './global/cart-preview';
import compareProducts from './global/compare-products';
import privacyCookieNotification from './global/cookieNotification';
import maintenanceMode from './global/maintenanceMode';
import carousel from './common/carousel';
import 'lazysizes';
import loadingProgressBar from './global/loading-progress-bar';
import sweetAlert from './global/sweet-alert';
import svgInjector from './global/svg-injector';
import imageHover from './custom/image-hover';
import './global/pinnacle/product';
import './global/pinnacle/global-theme';

import './modules/click-handlers'
import './modules/slider'
import './modules/player'

export default class Global extends PageManager {
    onReady() {
        // Only load visible elements until the onload event fires,
        // after which preload nearby elements.
        window.lazySizesConfig = window.lazySizesConfig || {};
        window.lazySizesConfig.loadMode = 1;

        quickSearch();
        currencySelector();
        foundation($(document));
        quickView(this.context);
        cartPreview();
        compareProducts(this.context.urls);
        carousel();
        menu();
        mobileMenuToggle();
        privacyCookieNotification();
        maintenanceMode(this.context.maintenanceMode);
        loadingProgressBar();
        sweetAlert();
        svgInjector();
        imageHover();

        $('.home').css({"marginTop":"0px"});
        if($(window).width() > 900){
           if($("body").hasClass("home")){ $(".slider.js-slider").css({'paddingTop' : $('body.home .header-container:not(.full-width)').height() });}
        }

           $(window).on('resize', function(){
            if($("body").hasClass("home")){ 
                  if($(window).width() > 1025){
                $(".slider.js-slider").css({'paddingTop' : $('body.home .header-container:not(.full-width)').height() });
            }else{
                $(".slider.js-slider").css({'paddingTop' : 0}); 
            }}
        })
         
    }
}
