/*
 * Click Handlers
 */

$('.btn-burger').on('click', function(e){
	e.preventDefault()

	$('.header').toggleClass('is-active')
})

$('.products .products__head a').on('click', function(event) {
	var $tabLink = $(this);
	var $targetTab = $($tabLink.attr('href'));

	$tabLink
		.parent()
		.add($targetTab)
		.addClass('is-active')
		.siblings()
		.removeClass('is-active');
		
		event.preventDefault();
});
