/*
 * Slider
 */

function initSliders() {
	$('.js-slider .slider__slides').slick()
}

$(window).on('load', function() {
	initSliders()
})
