$('.hero .hero__btn').on('click', function(e){
	e.preventDefault()

	$(this).closest('.hero').addClass('is-playing').closest('.hero').find('video')[0].play()
})


$('.article-def .article__btn').on('click', function(e){
	e.preventDefault()

	$(this).closest('.article__media').addClass('is-playing').closest('.article-def').find('video')[0].play()
})

$('.article-def video').on('ended', function() {
	$(this).closest('.article__media').removeClass('is-playing')
}).on('click', function() {
	if ($(this)[0].paused === true) {
		$(this)[0].play();
	} else {
		$(this)[0].pause();
	}
	
	$(this).closest('.article__media').toggleClass('is-paused')
})

$('.hero video').on('play', function() {
$(".hero__inner").hide();
});
$('.hero video').on('pause', function() {
//	$(".hero__inner").show();
	//$(".hero__bg").show();
});

$('.hero video').on('ended', function() {
	$(this).closest('.hero').removeClass('is-playing is-paused')
}).on('click', function() {
	if ($(this)[0].paused === true) {
		$(this)[0].play();
	} else {
		$(this)[0].pause();
	}
	
	$(this).closest('.hero').toggleClass('is-paused')
})
